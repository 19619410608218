import Vue from 'vue'
import moment from 'moment-timezone'

Vue.filter('formatDate', function(value, {
    format    = 'MM/DD/YYYY',
    timeZone  = "America/Denver",
    localTime = false
}) {

    // console.log(moment(value).format(format), moment(value).tz(timeZone).format(format))

    if(localTime) {
        return value ? moment(value).format(format) : ''
    } 

    return value ? moment(value).tz(timeZone).format(format) : ''
})