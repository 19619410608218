/* Plugins */
import moment from "moment"
import _ from 'lodash'


export const notificationMixins = {
    data() {
        return {
            // today    : '09/30/2023',
            today    : moment().format('MM/DD/YYYY'),
        }
    },
    computed : {
        /* Reminder list */
        reminders: {
            get() {
                return this.$store.state.reminders
            },
            set(reminders) {
                this.$store.commit('updateReminders', reminders)
            }
        },
        /* Notification state */
        enableNotification : {
            get() {
                return this.$store.state.enableNotification
            },
            set(state) {
                this.$store.commit('updateNotificationState', state)
            }
        }
    },
    methods : {
        /* Check for notification  */
        checkForNotification : _.throttle(function() {
            /* Notification will only send if on or 1 day before event */
            
            console.log('reminder test ', this.reminders)

            this.reminders.forEach(e => {
                console.log('reminder event ', e)
                if(e.remainDays == 0 && !e.remindedOnTheDay) {
                    const body  = "Today"
                    const title = e.label

                    this.sendNotification({title, body, callback : () => {
                        /* Update session storage list  */
                        e.remindedOnTheDay = true

                        localStorage.setItem('churchappReminders', JSON.stringify(this.reminders))
                    }})
                }
                
                if(e.remainDays == 1 && !e.remindedDayBefore) {
                    const body  = "Tomorrow"
                    const title = e.label

                    this.sendNotification({title, body, callback : () => {
                        console.log('callback day before')
                        /* Update session storage list  */
                        e.remindedDayBefore = true

                        localStorage.setItem('churchappReminders', JSON.stringify(this.reminders))
                    }})
                }
            })
        }, 1000),
        /* Check if event is on the reminder list */
        isReminded(event) {
            const eventId = `${event.dtstart_date}${event.label.toLowerCase().split(' ').join('')}`
            const list = this.reminders.map(e => {
                return `${e.dtstart_date}${e.label.toLowerCase().split(' ').join('')}`
            })

            // console.log('list', list, eventId)

            return list.includes(eventId)
        },
        /* Get reminders */
        getReminders() {
            let list = localStorage.getItem('churchappReminders')

            if(!list) return 

            list = JSON.parse(list)

          
            /* Add how many days remaining */
            list = [...list.map(e => {
                e.remainDays = moment(e.dtstart_date).diff(this.today, 'days')

                return e
            })]

            /* Remove past reminder */
            list = [...list.filter(e => e.remainDays > 0)]



            this.reminders = list
        },
        /* Add reminder */
        addReminder(event) {
            /* Check if subscribe  */
            if(!this.enableNotification) {
                this.initNotif()
            }

            /* Get reminder list */
            let list = this.reminders
            
            const remindEvent                   = {...event}
                  remindEvent.remindedDayBefore = false
                  remindEvent.remindedOnTheDay  = false

           /* Add reminder */
           list = [...list, remindEvent]

           console.log('list', list)

           localStorage.setItem('churchappReminders', JSON.stringify(list))

           this.getReminders()
        },
        /* Send notification */
        async sendNotification({body, title, callback = () => {}} = {}) {
            const registration = await navigator.serviceWorker.getRegistration();
                
            const payload = {
                body 
            }

            console.log('registration', registration)
            
            if(registration && 'showNotification' in registration) {
                registration.showNotification(title, payload);
            }

            else {
                new Notification(title, payload);
            }

            /* Run callback */
            callback()
        },
        /* Initialize notification and ask for permision */
        async initNotif() {
    

            /* Ask for permission */

            if(Notification.permission === 'granted') {
                this.enableNotification = true 
            }
            else {
                if(Notification.permission !== 'denied') {
                    const permission = await Notification.requestPermission();
                
                    if(permission === 'granted') {
                        this.enableNotification = true 
                    }
                }
            }

            // const sendNotification = async () => {
            //     if(Notification.permission === 'granted') {
            //         showNotification(notification.value);
            //     }
            //     else {
            //         if(Notification.permission !== 'denied') {
            //             const permission = await Notification.requestPermission();
                    
            //             if(permission === 'granted') {
            //                 showNotification(notification.value);
            //             }
            //         }
            //     }
            // };
                
            // const showNotification = body => {
            //     const title = 'What PWA Can Do Today';
                
            //     const payload = {
            //         body
            //     };
                
            //     if('showNotification' in registration) {
            //         registration.showNotification(title, payload);
            //     }
            //     else {
            //         new Notification(title, payload);
            //     }
            // };


        }
    }
}