/* helper */
import Helper from "./helpers";

export default class Api {
    static async fetch({
        url,
        params,
        method,
        baseUrl = null,
    }) {
        /* Process options */
       const options = {
        method : method,
        headers : {
            Authorization : `Bearer ${Helper.getCookie('springstoken')}`
        }
       }

        /* 
            Process params
        */
       const baseurl   = baseUrl ? baseUrl : process.env.VUE_APP_STAKEAPIBASEURL
       const newparams = new FormData()

       if(params) {
            for (const key in params) {
                const value = params[key];

                newparams.append(key, value)
            }

            options.body = newparams
       }

        // Process call
        const res = await fetch(`${baseurl}/${url}`, options)

        return res.json()
    }
}