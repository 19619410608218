import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    routeFrom          : null,
    user               : null,
    globalStakeCalendar: []
  },
  mutations: {
    /* Update stake calendar */
    updateStakeCalendar(state, payload) {
      state.globalStakeCalendar = payload
    },
    /* Update active user */
    user(state, payload) {
      state.user = payload

    },
    /* Update route from state */
    updateRouteFrom(state, payload) {
      state.routeFrom = payload
    }
  },
  actions: {
    /* Get user */
    getUser() {
      
    }
  },
})
