/**
 * Cron jobs 
 * Check jobs collection every 5 seconds
 * this is alternative for background sync because service worker background sync not supported in ios
 */ 

/* Jobs*/
import { notificationMixins } from "./notificationMixins";

export const crontabMixins = {
    mixins : [notificationMixins],
    data() {
        return {
            processing: false,
            failed    : []
        }
    },
    computed : {
        isonline() {
            return this.$store.state.isonline;
        },
        /* Webhook */
        socketio() {
            return this.$store.state.socketio
        },
        /* Logged in user */
        user() {
            return this.$store.state.user
        }
    },
    methods : {
        /**
         * Initialize Cron
         * Run everery 5 secs
         * Documentary : https://www.npmjs.com/package/vue-crontab
         */
        initCron() {
           /* Events job */
           this.$crontab.addJob({
                name    : 'eventsNotification',
                interval: {
                    seconds: '/2',
                },
                sync     : 1,
                // condition: () => this.user && !this.notifIsFetching ? true : false,
                job      : this.checkForNotification
            });
        },
       
    }
}