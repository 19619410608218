import Vue from 'vue'
import VueRouter from 'vue-router'
import navguard from '@/router/navguard.js'
import store from '@/store'

// import HomeView from '../views/HomeView.vue'

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
};

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('../views/defaultPage.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/',
  //   name: 'ward-info',
  //   component: HomeView,
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/templeschedule',
  //   name: 'templeSchedule',
  //   component: () => import('../views/templeSchedule.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/staketempleschedule',
  //   name: 'staketempleschedule',
  //   component: () => import('../views/templeSchedule.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/templeschedule/book',
  //   name: 'bookSchedule',
  //   component: () => import('../views/bookSched.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/staketempleschedule/book',
  //   name: 'bookStakeSchedule',
  //   component: () => import('../views/bookSched.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/cancelbookingtemple',
  //   name: 'cancelBooking',
  //   component: () => import('../views/cancelBooking.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/bishopmessages',
  //   name: 'bishopMessages',
  //   component: () => import('../views/bishopMessages.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/sundayprogram',
  //   name: 'sunday-program',
  //   component: () => import('../views/sundayprogram.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/wardcalendar',
  //   name: 'ward-calendar',
  //   component: () => import('../views/wardcalendar.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/calendar',
  //   name: 'google-calendar',
  //   component: () => import('../views/tempcalendar.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/announcements',
  //   name: 'announcements',
  //   component: () => import('../views/announcements.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/stakeinfo',
  //   name: 'stake-info',
  //   component: () => import('../views/stakeInfo.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/stakeconference',
  //   name: 'stakeConf',
  //   component: () => import('../views/stakeConference.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/privacypolicy',
  //   name: 'privacy-policy',
  //   component: () => import('../views/defaultPage.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  // {
  //   path: '/termsandcondition',
  //   name: 'terms-conditions',
  //   component: () => import('../views/defaultPage.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/forgotpassword',
    name: 'forgotPassword',
    component: () => import('../views/forgotPassword.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: () => import('../views/resetPassword.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/set-password',
    name: 'setPassword',
    component: () => import('../views/setPassword.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/',
    name: 'directory',
    component: () => import('../views/directory.vue'),
    meta : {
      pageGroup: 'directory',
      group    : ['geneva']
    }
  },
  {
    path: '/wards',
    name: 'wards',
    component: () => import('../views/wards.vue'),
    meta : {
      pageGroup: 'wards',
      group    : ['geneva']
    }
  },
  {
    path: '/knowledgebase',
    component: () => import('../views/knowledgeMain.vue'),
    children : [
      {
        path: '/',
        name: 'knowledge-base',
        component: () => import('../views/knowledgeList.vue'),
        meta : {
          pageGroup: 'knowledge-base',
          group    : ['geneva']
        }
      },
      {
        path: '/sub/:slug',
        name: 'knoledgeSubs',
        component: () => import('../views/knowledgeSubList.vue'),
        meta : {
          pageGroup: 'knowledge-base',
          group    : ['geneva']
        }
      },
      {
        path: '/list/:slug',
        name: 'knowledgeSubKnowledges',
        component: () => import('../views/knowledgeSubKnowledges.vue'),
        meta : {
          pageGroup: 'knowledge-base',
          group    : ['geneva']
        }
      },
      {
        path: '/view/:slug/:id',
        name: 'knowledgeView',
        component: () => import('../views/knowledgebase.vue'),
        meta : {
          pageGroup: 'knowledge-base',
          group    : ['geneva']
        }
      },
    ]
  },
  {
    path: '/stakecalendar',
    name: 'stakeCalendar',
    component: () => import('../views/stakeCalendar.vue'),
    meta : {
      pageGroup: 'stakeCalendar',
      group    : ['geneva']
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/settings.vue'),
    meta : {
      pageGroup: 'settings',
      group    : ['geneva']
    }
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('../views/map.vue'),
    meta : {
      pageGroup: 'map',
      group    : ['geneva']
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//NAVIGATION GUARD CHECKER

router.beforeEach( async (to, from, next) => {
  return navguard({to, from, next, store});
});


export default router
