/**
 * Navigation guard checking for sesssion
 */
// import {getCollections} from '@/helpers/localbase'

import Helper from '@/helpers/helpers'


 export default async function navguard ({to, next, store, from}) {
  
    // console.log(to, from, next, store, process.env)

    /**
     * Update route from store state
     */
    store.commit('updateRouteFrom', from)


    /**
     * Get user data from indexed db 
     */
    // const [user] = await getCollections({name : 'user'})
    let user = store.state.user

    /* Check if user is null */
    if(!user) {
        // Helper.axoisglobalconfig()
        await store.dispatch('getUser')
        user = store.state.user
    }


    /**
     * Check if public page 
     * then bypass auth checker
     */
    console.log(user, 'to', to)
    if(to.meta.group.includes('public')) {
        next()

        return;
    }



    /**
     * Check if has user data if none redirect to login
     */
     /* Redirect to login */
    //  if(!user) {
    //     /* prevent if login page already */
    //     Helper.deleteCookie('springstoken')
    //     if(to.name == 'login') {
    //         next()
    //     } else {
    //         next({name : 'login'})
    //     }
    //     return
    // }

    // check if has session if none redirect to login
    if(to.name != 'login') {
        if(!Helper.getCookie('springstoken')) {
            next({name : 'login'})
        }
    } else if(to.name == 'login') {
        if(!Helper.getCookie('springstoken')) {
            next()
        } else {
            next({name : 'directory'})
        }
    }

    next();
 }