<template>
  <div 
    :class="[browserType, `${$route.name}-app`]"
    id="app">

     <b-nav 
        fill
        v-if="showMenu"
        class="mainnav"
        >

        <div class="swiper swiper-menu">
          <div class="swiper-wrapper">
            <div 
              v-for="menu in menus"
              :key="menu.id"
              @click="$router.push({name : menu.slug})" 
              :class="['swiper-slide', {'active' : $route.meta.pageGroup == menu.slug}]">
              {{menu.menutitle}}
            </div>
          </div>
        </div>

          <!-- <b-nav-item 
            v-for="menu in menus"
            :key="menu.id"
            @click="$router.push({name : menu.slug})" 
            :active="$route.name == menu.slug">{{menu.menutitle}}</b-nav-item> -->

        <!-- <b-nav-item 
            @click="$router.push({name : 'sundayprogram'})" 
            :active="$route.name == 'sundayprogram'">Sunday Program</b-nav-item>

        <b-nav-item 
            @click="$router.push({name : 'wardcalendar'})" 
            :active="$route.name == 'wardcalendar'">Ward Calendar</b-nav-item> -->

          
      </b-nav>
    <router-view/>
  </div>
</template>

<script>
/* Decleration */
// Helper
import API from '@/helpers/fetch'
import Helper from '@/helpers/helpers'

/* Mixins */
import { notificationMixins } from './mixins/notificationMixins';
import { crontabMixins } from './mixins/crontabMixins';

/* Plugins */
import PullToRefresh from 'pulltorefreshjs';
import _ from 'lodash'
import Swiper from 'swiper';


export default {
    mixins : [crontabMixins, notificationMixins],
    data() {
      return {
        menus      : [],
        pullrefresh: null,
        swiperMenu : null
      }
    },
    computed : {
      /* Device type */
      browserType() {
        const device = Helper.getdevicetype('broswer')

        return device ? device : null
      },
      /* Show / hide top menu */
      showMenu () {
        let show = true

        if(this.$route.name == 'templeSchedule') show = false

        if(this.$route.name == 'staketempleschedule') show = false

        if(this.$route.name == 'stakeConf') show = false

        if(this.$route.name == 'login') show = false

        if(this.$route.name == 'bookSchedule') show = false

        if(this.$route.name == 'bookStakeSchedule') show = false

        if(this.$route.name == 'bishopMessages') show = false

        return show
      }
    },
    watch : {
      /* watch showMenu to reinit slider */
      showMenu(newState, oldState) {
        if(oldState == false && newState) {
           /* Re init menu slider */
           setTimeout(() => {
            this.initMenuSlider()
          }, 0);
        }
      },
      /* Watch route  */
      $route : {
        handler(newR, oldR){
          /* Get menu  */
          /* Prevent if old route and new route group is same */
          if(!_.isEqual(newR.meta.group, oldR.meta.group)) {
            this.getmenus()
          }
        },
        deep : true
      }
    },
    methods : {
      /* Init menu slider */
      initMenuSlider() {
        if(this.swiperMenu) {
          try {
            this.swiperMenu.destroy()
          } catch (error) {
              console.log('error updating menu')
          }
        } 

        this.swiperMenu = new Swiper('.swiper-menu', {
          slidesPerView: 'auto',
          spaceBetween : 30,
          // loop         : true,
        })
      },
      /* Init pull refresh  */
      initpullrefresh() {
        /* Prevent if not ios */
        if(Helper.getdevicetype() !== 'iOS') return

        this.pullrefresh = PullToRefresh.init({
          mainElement: 'body',
          distIgnore : 100,
          onRefresh() {
            window.location.reload();
          }
        });

      },
      /* Get menus*/
      async getmenus() {
        // console.log('route', this.$route)

        /* Reset menus */
        this.menus = []
        
        /* Get menu for geneva  */
        /* Prevent if no route */
        if(!this.$route.name) return

        if(this.$route.meta.group.includes('geneva')) {
          /* Temporary defince geneva menus  */

            this.menus = [
              {
                id  : Helper.makeString(5),
                slug: 'directory',
                menutitle : 'Directory'
              },
              {
                id       : Helper.makeString(5),
                slug     : 'wards',
                menutitle: 'Wards'
              },
              {
                id       : Helper.makeString(5),
                slug     : 'knowledge-base',
                menutitle: 'Knowledge Base'
              },
              {
                id       : Helper.makeString(5),
                slug     : 'stakeCalendar',
                menutitle: 'Stake Calendar'
              },
              {
                id       : Helper.makeString(5),
                slug     : 'map',
                menutitle: 'Map'
              },
              {
                id       : Helper.makeString(5),
                slug     : 'settings',
                menutitle: 'Settings'
              }
            ]

            /* Init menu slider */
            this.initMenuSlider()

            return
        }

        /* Get menu for public */
        if(this.$route.meta.group.includes('public')) {
          

          const pages = await API.fetch({
              // url   : 'wp/v2/pages?orderby=date&order=asc',
              url   : 'churchapp/v1/active_pages',
              method: 'GET'
          }) 
  
            /* Loop slug to get custom field for title */


            this.menus = pages.map(p => {

              return {
                id        : Helper.makeString(5),
                slug      : p.page_slug,
                menutitle : p.menu_title,
                menu_order: p.menu_order
              }
            }).sort((a, b) => a.menu_order - b.menu_order)

            /* Init menu slider */
            this.initMenuSlider()
          
        }

      }
    },
    beforeMount() {
      // /* Init notifications */
      // this.initNotif()

      // /* Init crontab */
      // this.initCron()
    },
    mounted () {
      /* Get menus */
      this.getmenus();
      /* Init pull refresh */
      this.initpullrefresh()
    }
}
</script>

<style lang="scss">
@import '@/scss/partials/_colors.scss';
@import '@/scss/partials/_breakpoints.scss';
@import '@/scss/output.scss';



body {
  &::-webkit-scrollbar {
    width : 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: #21242a;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }

  * {
    &::-webkit-scrollbar {
      width : 0;
      height: 0;
    }

    &::-webkit-scrollbar-track {
      background-color: #21242a;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      cursor: pointer;
    }

    .form-control {
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color     : $black;
        font-style: italic;
      }
  
      &::-moz-placeholder { /* Firefox 19+ */
        color     : $black;
        font-style: italic;
      }
  
      &:-ms-input-placeholder { /* IE 10+ */
        color     : $black;
        font-style: italic;
      }
  
      &:-moz-placeholder { /* Firefox 18- */
        color     : $black;
        font-style: italic;
      }
  
      &:focus {
        outline   : none;
        box-shadow: none;
      }
    }
  }

}



.isLoading {
  @keyframes colors {
      from {
          background-color: $gray3;
      }
  
      to {
          background-color: $gray2;
      }
  }

  background-color: $gray3;
  animation       : colors 1s infinite;
  text-indent     : -1000px;

  * {
    visibility: hidden;
  }
  
}

.isLoadingButton, .isLoadingButton:before, .isLoadingButton:after {
    border-radius      : 50%;
    width              : 2em;
    height             : 2em;
    animation-fill-mode: both;
    animation          : bblFadInOut 1.8s infinite ease-in-out;
  }

.isLoadingButton {
    color          : inherit;
    font-size      : 7px;
    position       : relative;
    text-indent    : -9999em;
    transform      : translateZ(0);
    animation-delay: -0.16s;
    margin-top     : -3.5em;
    margin-inline  : auto;

    &::after, &::before {
        content : '';
        position: absolute;
        top     : -20px;
        display : block;
    }

    &::before {
        left           : -3.5em;
        animation-delay: -0.32s;
    }
    &::after {
        left: 3.5em;
    }
    
    @keyframes bblFadInOut {
        0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
        40% { box-shadow: 0 2.5em 0 0 }
    }
          
}

.ptr--ptr {
  background-color: $gray2;
  color           : $black;

  .ptr--icon, .ptr--text {
    color : inherit;
  }
}

#app {
  font-family            : Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  color                  : #2c3e50;
  font-weight            : 500;

  &.Safari {
    .calendarEvents .calendareventwrapper .events .eventitem.stake .eventinfo .cat {
        padding-top : 5px;
    }
  }
}

.nav {
  &.mainnav {
    white-space   : nowrap;
    // padding-inline: 30px;
    display       : flex;
    // width         : 100vw;
    // overflow      : auto hidden;
    flex-wrap     : nowrap;

    .swiper {
      padding-inline: 30px;
      width         : 100%;

      .swiper-wrapper {
        .swiper-slide {
          flex          : 1;
          color         : $gray1;
          font-size     : 15px;
          padding-inline: 0;
          padding-block : 30px;
          cursor        : pointer;
          text-align    : center;

      

        &.active {
          color      : $blue;
          position   : relative;
          font-weight: 600;
          
          
          &::after {
            content      : '';
            border-bottom: 1.5px solid $blue;
            position     : absolute;
            bottom       : 30px;
            width        : 60%;
            height       : 2px;
            left         : 50%;
            right        : 0;
            transform    : translateX(-50%);
          }
        }
        }
      }
    }

    .nav-item {
      margin-right : 20px;

       &:last-child {
        margin-right : 0;
      }
    }

    .nav-link {
      color         : $gray1;
      font-size     : 15px;
      padding-inline: 0;
      padding-block : 30px;

     

      &.active {
        color      : $blue;
        position   : relative;
        font-weight: 600;
        
        
        &::after {
          content      : '';
          border-bottom: 1.5px solid $blue;
          position     : absolute;
          bottom       : 30px;
          width        : 60%;
          height       : 2px;
          left         : 50%;
          right        : 0;
          transform    : translateX(-50%);
        }
      }
    }
  }
}

.pageTitle {
  font-size    : 28px;
  font-weight  : 600;
  margin-bottom: 0;
}

.sectionTitle {
  font-size  : 18px;
  font-weight: 600;
  color      : $black;
}

.collapseSection {
  padding   : 15px;
  border-top: 1px solid $gray5;

  a {
    display    : flex;
    align-items: center;
    cursor     : pointer;

    * {
      cursor: pointer;
    }

    .collapseLabel {
      @extend .sectionTitle;
      flex : 1;
    }

    .b-icon.bi {
      color : $black;
    }
  }

  
}

// .flex {
//   display: flex;
// }

// .items-center {
//   align-items: center;
// }

// .text-blue {
//   color : $blue;
// }

// .\!text-blue {
//   color : $blue !important;
// }

// .w-0	{ width: 0px;}
// .w-px	{ width: 1px;}
// .w-0\.5	{ width: 0.125rem; /* 2px */}
// .w-1	{ width: 0.25rem; /* 4px */}
// .w-1\.5	{ width: 0.375rem; /* 6px */}
// .w-2	{ width: 0.5rem; /* 8px */}
// .w-2\.5	{ width: 0.625rem; /* 10px */}
// .w-3	{ width: 0.75rem; /* 12px */}
// .w-3\.5	{ width: 0.875rem; /* 14px */}
// .w-4	{ width: 1rem; /* 16px */}
// .w-5	{ width: 1.25rem; /* 20px */}
// .w-6	{ width: 1.5rem; /* 24px */}
// .w-7	{ width: 1.75rem; /* 28px */}
// .w-8	{ width: 2rem; /* 32px */}
// .w-9	{ width: 2.25rem; /* 36px */}
// .w-10	{ width: 2.5rem; /* 40px */}
// .w-11	{ width: 2.75rem; /* 44px */}
// .w-12	{ width: 3rem; /* 48px */}
// .w-14	{ width: 3.5rem; /* 56px */}
// .w-16	{ width: 4rem; /* 64px */}
// .w-20	{ width: 5rem; /* 80px */}
// .w-24	{ width: 6rem; /* 96px */}
// .w-28	{ width: 7rem; /* 112px */}
// .w-32	{ width: 8rem; /* 128px */}
// .w-36	{ width: 9rem; /* 144px */}
// .w-40	{ width: 10rem; /* 160px */}
// .w-44	{ width: 11rem; /* 176px */}
// .w-48	{ width: 12rem; /* 192px */}
// .w-52	{ width: 13rem; /* 208px */}
// .w-56	{ width: 14rem; /* 224px */}
// .w-60	{ width: 15rem; /* 240px */}
// .w-64	{ width: 16rem; /* 256px */}
// .w-72	{ width: 18rem; /* 288px */}
// .w-80	{ width: 20rem; /* 320px */}
// .w-96	{ width: 24rem; /* 384px */}
// .w-auto	{ width: auto;}
// .w-1\/2	{ width: 50%;}
// .w-1\/3	{ width: 33.333333%;}
// .w-2\/3	{ width: 66.666667%;}
// .w-1\/4	{ width: 25%;}
// .w-2\/4	{ width: 50%;}
// .w-3\/4	{ width: 75%;}
// .w-1\/5	{ width: 20%;}
// .w-2\/5	{ width: 40%;}
// .w-3\/5	{ width: 60%;}
// .w-4\/5	{ width: 80%;}
// .w-1\/6	{ width: 16.666667%;}
// .w-2\/6	{ width: 33.333333%;}
// .w-3\/6	{ width: 50%;}
// .w-4\/6	{ width: 66.666667%;}
// .w-5\/6	{ width: 83.333333%;}
// .w-1\/12	{ width: 8.333333%;}
// .w-2\/12	{ width: 16.666667%;}
// .w-3\/12	{ width: 25%;}
// .w-4\/12	{ width: 33.333333%;}
// .w-5\/12	{ width: 41.666667%;}
// .w-6\/12	{ width: 50%;}
// .w-7\/12	{ width: 58.333333%;}
// .w-8\/12	{ width: 66.666667%;}
// .w-9\/12	{ width: 75%;}
// .w-10\/12	{ width: 83.333333%;}
// .w-11\/12	{ width: 91.666667%;}
// .w-full	{ width: 100%;}
// .w-screen	{ width: 100vw;}
// .w-svw	{ width: 100svw;}
// .w-lvw	{ width: 100lvw;}
// .w-dvw	{ width: 100dvw;}
// .w-min	{ width: min-content;}
// .w-max	{ width: max-content;}
// .w-fit	{ width: fit-content;}

// .\!w-0	{ width: 0px !important;}
// .\!w-px	{ width: 1px !important;}
// .\!w-0\.5	{ width: 0.125rem !important; /* 2px */}
// .\!w-1	{ width: 0.25rem !important; /* 4px */}
// .\!w-1\.5	{ width: 0.375rem !important; /* 6px */}
// .\!w-2	{ width: 0.5rem !important; /* 8px */}
// .\!w-2\.5	{ width: 0.625rem !important; /* 10px */}
// .\!w-3	{ width: 0.75rem !important; /* 12px */}
// .\!w-3\.5	{ width: 0.875rem !important; /* 14px */}
// .\!w-4	{ width: 1rem !important; /* 16px */}
// .\!w-5	{ width: 1.25rem !important; /* 20px */}
// .\!w-6	{ width: 1.5rem !important; /* 24px */}
// .\!w-7	{ width: 1.75rem !important; /* 28px */}
// .\!w-8	{ width: 2rem !important; /* 32px */}
// .\!w-9	{ width: 2.25rem !important; /* 36px */}
// .\!w-10	{ width: 2.5rem !important; /* 40px */}
// .\!w-11	{ width: 2.75rem !important; /* 44px */}
// .\!w-12	{ width: 3rem !important; /* 48px */}
// .\!w-14	{ width: 3.5rem !important; /* 56px */}
// .\!w-16	{ width: 4rem !important; /* 64px */}
// .\!w-20	{ width: 5rem !important; /* 80px */}
// .\!w-24	{ width: 6rem !important; /* 96px */}
// .\!w-28	{ width: 7rem !important; /* 112px */}
// .\!w-32	{ width: 8rem !important; /* 128px */}
// .\!w-36	{ width: 9rem !important; /* 144px */}
// .\!w-40	{ width: 10rem !important; /* 160px */}
// .\!w-44	{ width: 11rem !important; /* 176px */}
// .\!w-48	{ width: 12rem !important; /* 192px */}
// .\!w-52	{ width: 13rem !important; /* 208px */}
// .\!w-56	{ width: 14rem !important; /* 224px */}
// .\!w-60	{ width: 15rem !important; /* 240px */}
// .\!w-64	{ width: 16rem !important; /* 256px */}
// .\!w-72	{ width: 18rem !important; /* 288px */}
// .\!w-80	{ width: 20rem !important; /* 320px */}
// .\!w-96	{ width: 24rem !important; /* 384px */}
// .\!w-auto	{ width: auto !important;}
// .\!w-1\/2	{ width: 50% !important;}
// .\!w-1\/3	{ width: 33.333333% !important;}
// .\!w-2\/3	{ width: 66.666667% !important;}
// .\!w-1\/4	{ width: 25% !important;}
// .\!w-2\/4	{ width: 50% !important;}
// .\!w-3\/4	{ width: 75% !important;}
// .\!w-1\/5	{ width: 20% !important;}
// .\!w-2\/5	{ width: 40% !important;}
// .\!w-3\/5	{ width: 60% !important;}
// .\!w-4\/5	{ width: 80% !important;}
// .\!w-1\/6	{ width: 16.666667% !important;}
// .\!w-2\/6	{ width: 33.333333% !important;}
// .\!w-3\/6	{ width: 50% !important;}
// .\!w-4\/6	{ width: 66.666667% !important;}
// .\!w-5\/6	{ width: 83.333333% !important;}
// .\!w-1\/12	{ width: 8.333333% !important;}
// .\!w-2\/12	{ width: 16.666667% !important;}
// .\!w-3\/12	{ width: 25% !important;}
// .\!w-4\/12	{ width: 33.333333% !important;}
// .\!w-5\/12	{ width: 41.666667% !important;}
// .\!w-6\/12	{ width: 50% !important;}
// .\!w-7\/12	{ width: 58.333333% !important;}
// .\!w-8\/12	{ width: 66.666667% !important;}
// .\!w-9\/12	{ width: 75% !important;}
// .\!w-10\/12	{ width: 83.333333% !important;}
// .\!w-11\/12	{ width: 91.666667% !important;}
// .\!w-full	{ width: 100% !important;}
// .\!w-screen	{ width: 100vw !important;}
// .\!w-svw	{ width: 100svw !important;}
// .\!w-lvw	{ width: 100lvw !important;}
// .\!w-dvw	{ width: 100dvw !important;}
// .\!w-min	{ width: min-content !important;}
// .\!w-max	{ width: max-content !important;}
// .\!w-fit	{ width: fit-content !important;}

[placeholder]:empty::before { content: attr(placeholder); color: #555; } [placeholder]:empty:focus::before { content: ""; }

.btn {
  min-height: 57px;

  &-secondary {
    background-color: $black;
  }

  &-regular {
    background-color: $gray2;
    color           : $black;
    border-color    : $gray2;
    font-weight     : 600;
    padding         : 15px;


    &:hover, &:focus, &:active {
      background-color: $gray2 !important;
      border-color    : $gray2 !important;
      color           : $black !important;
      box-shadow      : none !important;
    }
  }

  
  &-outline-secondary {
    display        : flex !important;
    align-items    : center !important;
    justify-content: center !important;
    color          : $gray !important;
    font-weight    : 600 !important;
    border-color   : $gray7 !important;
    border-width   : 1.5 !important;

    &:hover {
      color : white !important;

      * {
        color : white !important;
      }
    }
  }

}

.backBtn {
  color    : $black;
  font-size: 20px;
}

.tabs {
  .nav-tabs {
    border-bottom : none;

    .nav-item {
      .nav-link {
        color        : $gray1;
        font-weight  : 400;
        font-size    : 13px;
        border-color: transparent;

        &.active {
          
          font-weight: 600;
          color      : $black;
          position   : relative;

          &::after {
            border-bottom: 1px solid $black;
            content      : '';
            display      : block;
            position     : absolute;
            bottom       : 0;
            width        : calc(100% - 30px);
          }
        }
      }
    }
  }

  .tab-content {
    margin-top: 0;
    padding   : 15px;
  }
}


.subTitle {
  color: $blue;
  padding-right: 5px;
  font-size    : 15px;
  font-weight  : 400;
  min-height: 25px;
}

.addressItem {
  .label {
    font-size  : 15px;
    font-weight: 400;
  }

  .address {
          margin-bottom: 15px;
          display      : flex;
  
          .theaddress {
              flex         : 1;
              color        : $blue;
              padding-right: 5px;
              font-size    : 15px;
              font-weight  : 400;
          }
  
          .markericon {
              margin-right: 5px;
              color: $blue;
          }
  
          a {
              g {
                  svg {
                      &:last-child {
                          color: white;
                      }
  
                      &:first-child {
                          color: $gray1;
                      }
                  }
              }
          }
      }
}



.nameSection {
  margin-top : 5px;
  display    : flex;
  align-items: center;

  .nameLabel {
    font-size   : 15px;
    margin-right: 10px;
    color       : $gray1;
  }

  .nameValue {
    font-size  : 15px;
    font-weight: 600;
    color      : $black;
  }


}

 .personCard {
      text-align: center;

      img {
          width          : 76px;
          height         : 76px;
          overflow       : hidden;
          border-radius  : 100%;
          object-fit     : cover;
          object-position: top;
          margin-bottom  : 5px;
      }

      .b-icon.bi {
          font-size : 76px;
      }

      .personCardName {
          font-size  : 14px;
          font-weight: 600;
      }

      .personCardPosition {
          @extend .personCardName;

          color      : $gray1;
          
      }
      
  }
  

.hymm {
    background-color: #fff;
    display         : flex;
    align-items     : center;
    padding         : 10px;
    margin-top      : 20px;
    margin-bottom   : 20px;

    svg {
        font-size   : 30px;
        color       : $gray4;
    }

    .hymminfo {
        flex      : 1;
        text-align: center;

        .hymmtitle {
            font-weight : 600;
            padding-left: 15px;

            a {
              color : $blue;
            }
        }

        p {
            margin-bottom: 0;
            color        : $gray1;
            font-size    : 13px;
            font-weight  : 500;
            
        }
    }
}

.churchAccordion {
  margin-top   : 30px;
  padding-left : 30px;
  padding-right: 30px;

  .card {
    border-left  : none;
    border-right : none;
    border-top: 1px solid $gray3;

    &:first-child {
      border-top: 0;
    }
    
    &:last-child {
      border-bottom : 0;
    }

  }

  .card-body {
    padding-left : 0;
    padding-right: 0;
  }

  header {
    &.card-header {
      background-color: transparent;
      border-color    : transparent;
      padding         : 15px 0;

      & > div {
        display    : flex;
        align-items: center;

        &.not-collapsed {
          .hide {
            display: none;
          }
        }

        &.collapsed {
          .show {
            display: none;
          }
        }
      }

      .headertitle {
        flex       : 1;
        font-weight: 600;
        color      : $black;
        font-size  : 18px;
      }

    }
  }

  .collapse {
    visibility: visible;
  }
  
  .staff {
    display      : flex;
    align-items  : center;
    margin-bottom: 15px;

    .staffpic {
      flex         : 0 0 40px;
      height       : 40px;
      border-radius: 100%;
      overflow     : hidden;
      margin-right : 10px;
      border       : 1px solid $gray3;


      img {
        width     : 100%;
        height    : 100%;
        object-fit: cover;
      }

      .imageplaceholder {
        width          : 100%;
        height         : 100%;
        display        : flex;
        flex           : 1;
        align-items    : center;
        justify-content: center;

        .b-icon.bi {
          color : $gray3;
        }
      }
    }

    .staffinfo {
      flex : 1;

      label {
        font-weight  : 600;
        font-size    : 14px;
        color        : $gray1;
        margin-bottom: 0;
      }

      .staffname {
        font-size  : 15px;
        color      : $black;
        font-weight: 600;
      }
    }

    .staffcontact {
      color      : $gray1;
      font-size  : 15px;
      font-weight: 600;
      display    : flex;
      align-items: center;

      a {
        color       : inherit;
        font-size   : inherit;
        font-weight : inherit;
        margin-right: 15px;

        .bi {
          font-size: 20px;
          color    : $gray6;
        }
      }
    }
  }
}

.calendarEvents{
    padding         : 0 30px 30px;
    background-color: $gray2;
    // margin-top      : -90px;
    min-height      : 100vh;

    h1 {
        font-size       : 21px;
        margin-bottom   : 0;
        padding-bottom  : 30px;
        font-weight     : 600;
        position        : sticky;
        z-index         : 150;
        top             : 0;
        background-color: $gray2;
    }

    .calendareventwrapper {
      padding-bottom : 150px;

        h2 {
          font-size       : 20px;
          font-weight     : 600;
          position        : sticky;
          top             : 145px;
          z-index         : 100;
          background-color: $gray2;
          margin-bottom   : 0;
          padding-bottom  : 16px;

            .year {
                color    : $gray1;
                font-size: 18px;
            }
        }


        .eventsectionitem {
            // margin-top : 30px;
        }

        .events {
            .eventitem {
                display      : flex;
                align-items  : center;
                margin-bottom: 20px;

                &.stake {
                  // .eventinfo {
                  //     .cat {
                  //         background-color: $blue;
                  //         padding         : 2px 8px;
                  //         padding-top     : 3.5px;
                  //         color           : white;
                  //         border-radius   : 50px;
                  //         width           : fit-content;
                  //         line-height     : 0;
                  //         display         : flex;
                  //         align-items     : center;
                  //         height          : 23px;
                  //         vertical-align  : middle;
                  //         margin-bottom   : 4px;
                  //     }
                  // }
              }

                .date {
                    padding-left : 20px;
                    padding-right: 20px;
                    font-weight  : 600;
                    text-align   : center;
                    flex         : 0 0 69px;
                    width        : 69px;

                    .dayName {
                      font-size    : 14px;
                    }
                }

                .eventinfo {
                    background-color: #fff;
                    padding         : 15px;
                    flex            : 1;
                    position        : relative;

                    .remindBtn {
                      position: absolute;
                      top     : 10px;
                      right   : 10px;
                      color   : $gray6;

                      .church-icon {
                        width : 20px;
                        height: 20px;
                      }
                    }

                    .cat {
                        color      : $blue;
                        font-size  : 12px;
                        font-weight: 600;
                    }

                    .eventname {
                        font-weight: 600;
                        font-size  : 15px;
                    }

                    .eventtime {
                        font-size: 15px;
                        color    : $gray1;

                        .eventSTime, .eventETime {
                          text-transform: uppercase;
                        }
                    }

                    .eventdesc {
                        @extend .eventtime;

                        font-size : 12px;


                        a {
                          padding-left : 2px;
                          word-break: break-all;
                        }
                    }
                }
            }
        }
    }
}
</style>

